import { Component, ComponentRef, EventEmitter, Input } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { SmartBannerSettings } from './settings.interface';
import { CookieService } from './utils/cookie.service';
import { NgFor, NgStyle } from '@angular/common'
import { StarComponent } from "./star/star.component";

@Component({
  selector: 'lib-smart-banner',
  standalone: true,
  templateUrl: './smart-banner.component.html',
  styleUrls: ['./smart-banner.component.scss'],
  imports: [NgStyle, NgFor, StarComponent]
})
export class SmartBannerComponent {
  @Input() public settings: SmartBannerSettings | undefined;
  @Input() public componentRef: ComponentRef<SmartBannerComponent> | undefined;
  public modifier: string | undefined;
  public onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly platform: Platform,
    private readonly cookieService: CookieService
  ) {
    this.modifier = this.getModifier();
  }

  private getModifier(): string | undefined {
    return this.platform.ANDROID ? 'android' : this.platform.IOS ? 'ios' : undefined;
  }

  public get icon(): string | undefined {
    return this.platform.ANDROID
      ? this.settings?.icon?.android
      : this.platform.IOS
      ? this.settings?.icon?.ios
      : undefined;
  }

  public get buttonUrl(): string {
    return this.platform.ANDROID
      ? this.settings?.buttonUrl?.android ?? '#'
      : this.platform.IOS
      ? this.settings?.buttonUrl?.ios ?? '#'
      : '#';
  }

  public get priceSuffix(): string {
    return this.platform.ANDROID
      ? this.settings?.priceSuffix?.android ?? ''
      : this.platform.IOS
      ? this.settings?.priceSuffix?.ios ?? ''
      : '';
  }

  public exit(): void {
    this.cookieService.set('smartbanner_closed', '1', this.endDate('exit'));
    this.onClose.emit();
    this.componentRef?.destroy();
  }

  public view(): void {
    this.cookieService.set('smartbanner_closed', '1', this.endDate('view'));
    this.onClose.emit();
    this.componentRef?.destroy();
  }

  private endDate(type: string): Date | null {
    const date = new Date();
    const timeToLive = type === 'exit'
      ? this.settings?.daysHidden ?? 0
      : this.settings?.daysReminder ?? 0;
    date.setDate(date.getDate() + timeToLive);
    return date;
  }

  public get reviewAverage(): number {
    return this.platform.ANDROID
      ? this.settings?.rating?.android ?? 5
      : this.platform.IOS
      ? this.settings?.rating?.ios ?? 5
      : 5;
  }
}
