import { inject, Injectable, isDevMode, DestroyRef } from '@angular/core';
import { Messaging, getToken, onMessage, deleteToken } from '@angular/fire/messaging';
import { Observable, BehaviorSubject, from, catchError, EMPTY, Subject, takeUntil } from 'rxjs';
import { log } from '@nx-superprep/utils';
import { ConsentService } from './consent.service';
import { environment } from '@nx-superprep/environment';

export interface FcmMessage {
  notification?: {
    title?: string;
    body?: string;
  };
  data?: Record<string, string>;
}

export interface FcmStatus {
  isEnabled: boolean;
  token: string | null;
  permission: NotificationPermission;
}

@Injectable({ providedIn: 'root' })
export class FcmService {
  private readonly messaging = inject(Messaging);
  private readonly consentService = inject(ConsentService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly destroy$ = new Subject<void>();

  private readonly status = new BehaviorSubject<FcmStatus>({
    isEnabled: false,
    token: null,
    permission: 'default'
  });

  readonly status$ = this.status.asObservable();

  constructor() {
    // Initialize FCM if consent is already granted
    if (this.consentService.getConsent()) {
      this.initializeFcm().catch(err =>
        log.error('Failed to initialize FCM during construction:', err)
      );
    }

    // Cleanup on destroy
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
      this.status.complete();
    });
  }

  /**
   * Initializes the Firebase Cloud Messaging service.
   * @returns Promise that resolves when initialization is complete
   * @throws Error if initialization fails
   */
  private async initializeFcm(): Promise<void> {
    const shouldEnable = !isDevMode() && environment.production;

    if (!shouldEnable || !('serviceWorker' in navigator)) {
      this.updateStatus({ isEnabled: false });
      log.debug('🔴FCM service worker is not enabled - environment conditions not met');
      return;
    }

    try {
      const swRegistration = await navigator.serviceWorker.register(
        '/firebase-messaging-sw.js',
        { scope: '/' }
      );

      const token = await this.requestToken(swRegistration);

      this.updateStatus({
        isEnabled: true,
        token,
        permission: Notification.permission
      });

      log.debug('🟢FCM service worker successfully enabled');
    } catch (err) {
      this.updateStatus({ isEnabled: false });
      throw new Error('Failed to initialize FCM: ' + (err instanceof Error ? err.message : String(err)));
    }
  }

  /**
   * Requests an FCM token for the given service worker registration.
   * @param swRegistration - The ServiceWorkerRegistration to use
   * @returns Promise that resolves with the FCM token
   * @throws Error if token retrieval fails
   */
  private async requestToken(swRegistration: ServiceWorkerRegistration): Promise<string> {
    try {
      const token = await getToken(this.messaging, {
        vapidKey: environment.config.firebase.vapidKey,
        serviceWorkerRegistration: swRegistration
      });

      if (!token) {
        throw new Error('No token received from FCM');
      }

      await this.saveTokenToDatabase(token);
      return token;
    } catch (err) {
      throw new Error('Failed to retrieve FCM token: ' + (err instanceof Error ? err.message : String(err)));
    }
  }

  /**
   * Requests notification permission from the user and initializes FCM if granted.
   * @returns Promise that resolves with the permission status
   */
  async requestPermission(): Promise<NotificationPermission> {
    try {
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        this.consentService.setConsent(true);
        await this.initializeFcm();
      } else {
        this.consentService.setConsent(false);
        this.updateStatus({ permission });
        log.warn(`Notification permission ${permission}`);
      }

      return permission;
    } catch (err) {
      log.error('Failed to request notification permission:', err);
      throw new Error('Failed to request notification permission: ' +
        (err instanceof Error ? err.message : String(err)));
    }
  }

  /**
   * Listens for incoming FCM messages.
   * @returns Observable of FCM messages
   */
  getMessages(): Observable<FcmMessage> {
    if (!this.status.getValue().isEnabled) {
      log.warn('Attempting to get messages while FCM is disabled');
      return EMPTY;
    }

    return new Observable<FcmMessage>(subscriber =>
      onMessage(this.messaging, message => subscriber.next(message as FcmMessage))
    ).pipe(
      takeUntil(this.destroy$),
      catchError(err => {
        log.error('Error receiving FCM message:', err);
        return EMPTY;
      })
    );
  }

  /**
   * Deletes the current FCM token and removes it from the database.
   * @returns Promise that resolves when the token is deleted
   */
  async deleteFcmToken(): Promise<void> {
    try {
      await deleteToken(this.messaging);
      await this.removeTokenFromDatabase();
      this.updateStatus({ token: null });
      log.debug('FCM token deleted successfully');
    } catch (err) {
      throw new Error('Failed to delete FCM token: ' +
        (err instanceof Error ? err.message : String(err)));
    }
  }

  /**
   * Updates the FCM status and notifies subscribers.
   * @param partial - Partial status update
   */
  private updateStatus(partial: Partial<FcmStatus>): void {
    this.status.next({
      ...this.status.getValue(),
      ...partial
    });
  }

  /**
   * Saves the FCM token to the database for the current user.
   * @param token - The FCM token to save
   */
  private async saveTokenToDatabase(token: string): Promise<void> {
    // TODO: Implement token storage in database
    log.debug('Saving FCM token to database:', token);
  }

  /**
   * Removes the FCM token from the database for the current user.
   */
  private async removeTokenFromDatabase(): Promise<void> {
    // TODO: Implement token removal from database
    log.debug('Removing FCM token from database');
  }
}
